import React, { FC } from 'react';
import {
  ActionButtonUiTypes,
  BookingsGroup,
  TabTypes,
} from '../../../../../../../types';
import BookingBodyContentRow from './BookingBodyContentRow';
import { BookingBodyContentDataHooks } from '../../../../datahooks';
import { classes } from './BookingBodyContent.st.css';
import { ReviewFormSubmission } from '../../../../ActionsButtons/ReviewFormSubmission';
import { useApplicationContext } from '../../../../../../../core/hooks/useApplicationContext';

export interface BookingBodyContentProps {
  group: BookingsGroup;
}

const BookingBodyContent: FC<BookingBodyContentProps> = ({ group }) => {
  const { selectedTab } = useApplicationContext();
  const withServiceName = group.bookings.length > 1;

  const rows = group.bookings.map((booking) => {
    return (
      <div className={classes.item} data-hook={BookingBodyContentDataHooks.Row}>
        <BookingBodyContentRow
          key={booking.bookingId}
          booking={booking}
          withServiceName={withServiceName}
        />
      </div>
    );
  });

  return (
    <div className={classes.root} data-hook={BookingBodyContentDataHooks.Main}>
      {rows}
      {selectedTab.type === TabTypes.HISTORY && (
        <ReviewFormSubmission group={group} type={ActionButtonUiTypes.LINK} />
      )}
    </div>
  );
};

export default BookingBodyContent;
