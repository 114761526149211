import React, { FC } from 'react';
import {
  ActionButtonProps,
  ActionButtonUiTypes,
  ActionsMenuType,
  BookingsGroup,
  MemberAreaClickActions,
  ModalType,
} from '../../../../../types';
import { Document } from '@wix/wix-ui-icons-common/on-stage';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { classes as classesActionsMenu } from '../../ManageButton/ManageButton.st.css';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';
import { SubmissionViewModalProps } from '../../../../../core/ModalFacade/modals/SubmissionViewModal';
import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import {
  ActionsButtonsDataHooks,
  ReviewFormSubmissionDataHooks,
} from '../../datahooks';
import { useBiLogger } from '../../../../../core/bi/biLoggerFactory';
import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-bookings-data/v2';
import Link from '../../../Components/Link';
import { classes } from './ReviewFormSubmission.st.css';
import { shouldShowSubmission } from '../../../../../utils/bookInfo';
import { ExperimentsConsts } from '../../../../../consts/experiments';

export interface ReviewFormSubmissionProps extends ActionButtonProps {
  group: BookingsGroup;
}

export const ReviewFormSubmission: FC<ReviewFormSubmissionProps> = ({
  group,
  type,
  onActionClick,
}) => {
  const { t } = useTranslation();
  const state = useApplicationContext();
  const { actions, selectedTab } = state;
  const biLogger = useBiLogger(state);
  const { experiments } = useExperiments();

  let content: React.ReactNode = null;

  const handleClick = () => {
    actions.showModal<SubmissionViewModalProps>(ModalType.Submission, {
      group,
    });
    biLogger?.report(
      bookingsLoginUserAccountSeeAllServicesClick({
        action: MemberAreaClickActions.ViewFormSubmission,
        referralInfo: selectedTab.type,
      }),
    );
  };

  if (type === ActionButtonUiTypes.MENU) {
    content = (
      <ActionsMenuLayout
        className={classesActionsMenu.actionMenuLayout}
        data-hook={ActionsButtonsDataHooks.ReviewFormSubmission}
      >
        <ActionsMenuLayout.Item
          content={t(
            'app.my-bookings-widget.review-form-submission-button.label',
          )}
          onClick={() => {
            handleClick();
            onActionClick?.(ActionsMenuType.REVIEW_FORM_SUBMISSION);
          }}
          prefixIcon={<Document />}
        />
      </ActionsMenuLayout>
    );
  } else if (type === ActionButtonUiTypes.LINK) {
    const isWixFormsMigrationEnabled = experiments.enabled(
      ExperimentsConsts.WixFormsMigration,
    );

    const isMyBookingsShowFormSubmissionEnabled = experiments.enabled(
      ExperimentsConsts.MyBookingsShowFormSubmission,
    );

    if (
      !isWixFormsMigrationEnabled ||
      !isMyBookingsShowFormSubmissionEnabled ||
      !shouldShowSubmission(group)
    ) {
      return null;
    }

    content = (
      <div className={classes.root}>
        <Document className={classes.icon} />
        <Link className={classes.link} onClick={handleClick}>
          {t('app.my-bookings-widget.review-form-submission-button.label')}
        </Link>
      </div>
    );
  }

  return <div data-hook={ReviewFormSubmissionDataHooks.Main}>{content}</div>;
};
