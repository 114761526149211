import {
  BookingStatus,
  ExtendedBooking,
} from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { Money } from '@wix/ambassador-bookings-fees-v1-booking-fee/types';
import {
  PagingMetadataV2,
  Service,
  ServiceType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { PriceUtils } from '@wix/bookings-uou-mappers';
import { BookingDTO, Bookings, BookingsGroup } from '../../types';
import { SCHEDULING_FORM_FIELD_APP_DEF_ID } from '../../consts/applicationIdentities';

export const isCourse = (
  groupOrBooking?: BookingsGroup | BookingDTO,
): boolean => {
  const firstBooking =
    (groupOrBooking as BookingsGroup)?.bookings?.[0] ||
    (groupOrBooking as BookingDTO);

  return firstBooking?.service?.type === ServiceType.COURSE;
};

export const isPendingApproval = (group: BookingsGroup): boolean => {
  return group.bookings.some(
    (booking) => booking.status === BookingStatus.PENDING,
  );
};

export const isWaitList = (group: BookingsGroup): boolean => {
  return group.bookings.some(
    (booking) => booking.status === BookingStatus.WAITING_LIST,
  );
};

export const isBookAnotherAble = (group: BookingsGroup): boolean => {
  return !!(
    !isCourse(group) &&
    group.bookings.length === 1 &&
    group.bookings[0].service.id &&
    group.allowedActions?.bookAnother &&
    !group.bookings[0].service.hidden
  );
};

export const isRescheduleAble = (group: BookingsGroup): boolean => {
  return !!(
    group?.allowedActions?.reschedule &&
    group.bookings.every((booking) => {
      return (
        booking.service.id &&
        !isBookingApprovalFlow(booking.service!) &&
        booking.status !== BookingStatus.PENDING &&
        booking.service?.type !== ServiceType.COURSE &&
        !booking.isDynamicPrice &&
        !booking.shouldBlockRescheduleDueToInvalidPricingPlan &&
        !booking.service.hidden
      );
    })
  );
};

export const isCancelAble = (group: BookingsGroup): boolean => {
  return !!group?.allowedActions?.cancel;
};

export const isAvailableOnline = (group: BookingsGroup): boolean => {
  return (
    group.bookings.some((booking) => !!booking.videoConference) &&
    !isWaitList(group) &&
    !isPendingApproval(group)
  );
};

export const isBookingApprovalFlow = (service: Service): boolean =>
  !!service?.onlineBooking?.requireManualApproval;

export const getServiceIdFromExtendedBooking = (
  extendedBooking: ExtendedBooking,
): string =>
  extendedBooking?.booking?.bookedEntity?.schedule?.serviceId ||
  extendedBooking?.booking?.bookedEntity?.slot?.serviceId!;

export const getGroupCancellationFeeDetails = (
  listOfMoney: Money[],
  locale: string,
): Money | undefined => {
  if (!listOfMoney.length) {
    return undefined;
  }

  const currency = listOfMoney.find((money) => money.currency)?.currency || '';
  let sum = 0;

  for (const money of listOfMoney) {
    sum += parseFloat(money?.value || '0');
  }

  const formattedValue = PriceUtils.getFormattedCurrency({
    price: sum,
    currency,
    locale,
  });

  return {
    value: sum.toString(),
    currency,
    formattedValue,
  };
};

export const isCancellationFeePolicy = (group: BookingsGroup): boolean => {
  return group.bookings.some(
    (booking) =>
      booking.withCancellationFeePolicy &&
      booking.status === BookingStatus.CONFIRMED,
  );
};

export const getUpdatedGroups = (
  isFirstLoad: boolean,
  newBookings: Bookings,
  existingBookings?: Bookings,
) => {
  return isFirstLoad
    ? newBookings.groups
    : [...existingBookings?.groups!, ...newBookings.groups];
};

export const getOffset = (pagingMetadata: PagingMetadataV2 | undefined) => {
  return pagingMetadata ? pagingMetadata.count! + pagingMetadata.offset! : 0;
};

export const getGroupSubmissionId = (
  group: BookingsGroup,
): string | null | undefined => {
  const booking = group.bookings[0];

  return booking?.submissionId;
};

export const hasSubmission = (group: BookingsGroup): boolean => {
  return !!getGroupSubmissionId(group);
};

export const isFromApp = (group: BookingsGroup, appDefId: string): boolean => {
  return group.bookings?.[0]?.sourceAppDefId === appDefId;
};

export const shouldShowSubmission = (group: BookingsGroup): boolean =>
  hasSubmission(group) && !isFromApp(group, SCHEDULING_FORM_FIELD_APP_DEF_ID);
