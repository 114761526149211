import moment from 'moment-timezone';
import { useCallback } from 'react';
import { useApplicationContext } from '../useApplicationContext';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { ExperimentsConsts } from '../../../consts/experiments';

export enum TimezoneFormatType {
  NAME = 'name`',
  OFFSET = 'offset',
}

export interface Formatters {
  getStartTime: (start: Date) => string;
  getStartDate: (start: Date) => string;
  getTimezoneName: (
    timeZoneFormatType: TimezoneFormatType,
    overrideTimezone?: string,
  ) => string | undefined;
  getDuration: (start: Date, end: Date) => string;
}

export const useFormatters = (): Formatters => {
  const { selectedTimezone } = useApplicationContext();
  const { timezone } = selectedTimezone || {};

  const { t } = useTranslation();
  const { language } = useEnvironment();
  const { experiments } = useExperiments();
  const isMomentFallbackEnabled = experiments.enabled(
    ExperimentsConsts.UseMoment,
  );

  const createIntl = (options?: Intl.DateTimeFormatOptions) => {
    return new Intl.DateTimeFormat(language, {
      timeZone: timezone,
      ...options,
    });
  };

  const getStartTime = useCallback(
    (start: Date) => {
      if (isMomentFallbackEnabled && timezone) {
        return moment(start).tz(timezone).locale(language).format('LT');
      }

      return createIntl({ hour: 'numeric', minute: 'numeric' }).format(start);
    },
    [timezone, language, isMomentFallbackEnabled],
  );

  const getStartDate = useCallback(
    (start: Date) => {
      if (isMomentFallbackEnabled && timezone) {
        return moment(start).tz(timezone).locale(language).format('LL');
      }
      return createIntl({
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(start);
    },
    [timezone, language, isMomentFallbackEnabled],
  );

  const getTimezoneName = useCallback(
    (timeZoneFormatType: TimezoneFormatType, overrideTimezone?: string) => {
      const zoneId = overrideTimezone || timezone;
      if (!zoneId) {
        return;
      }

      if (isMomentFallbackEnabled) {
        const now = Date.now();
        const zone = moment.tz.zone(zoneId);

        if (!zone) {
          return;
        }
        if (timeZoneFormatType === TimezoneFormatType.NAME) {
          return new Intl.DateTimeFormat(language, {
            year: 'numeric',
            timeZoneName: 'long',
            ...(zoneId ? { timeZone: zoneId } : {}),
          })
            .formatToParts(now)
            .find(({ type }) => type === 'timeZoneName')?.value;
        }

        const offset = moment.tz(zoneId).format('Z');
        return `GMT${offset.slice(0, 3)}`;
      }
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        timeZoneName:
          timeZoneFormatType === TimezoneFormatType.NAME ? 'long' : 'short',
        ...(zoneId ? { timeZone: zoneId } : {}),
      };

      return createIntl(options)
        .formatToParts(new Date())
        .find(({ type }) => type === 'timeZoneName')?.value;
    },
    [timezone, language, isMomentFallbackEnabled],
  );

  const getDuration = useCallback((start: Date, end: Date) => {
    const duration = end.getTime() - start.getTime();
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor(duration / (1000 * 60 * 60)) % 24;
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    const result = [];

    if (days > 0) {
      result.push(
        t('app.my-bookings-widget.bookings-details.duration.days', {
          days,
        }),
      );
    }

    if (hours > 0) {
      result.push(
        t('app.my-bookings-widget.bookings-details.duration.hours', {
          hours,
        }),
      );
    }

    if (minutes > 0) {
      result.push(
        t('app.my-bookings-widget.bookings-details.duration.minutes', {
          minutes,
        }),
      );
    }

    return result.join(' ');
  }, []);

  return {
    getStartTime,
    getStartDate,
    getTimezoneName,
    getDuration,
  };
};
