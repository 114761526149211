import React, { FC, useEffect } from 'react';
import { ModalFacadeProps } from '../..';
import { BookingsGroup } from '../../../../types';
import { useApplicationContext } from '../../../hooks/useApplicationContext';
import { InputDialog } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CenteredSpinner } from '../../../../components/MyBookings/Components/CenteredSpinner';
import { Submission } from './Submission';
import { classes } from './SubmissionViewModal.st.css';
import { SubmissionViewModalDataHooks } from '../../../../components/MyBookings/Widget/datahooks';

export interface SubmissionViewModalProps {
  group: BookingsGroup;
}

const SubmissionViewModal: FC<SubmissionViewModalProps & ModalFacadeProps> = ({
  group,
}) => {
  const { actions, submissionInfo } = useApplicationContext();
  const { t } = useTranslation();

  const formSubmission =
    submissionInfo?.[group.groupId || group.bookings[0].bookingId!];

  useEffect(() => {
    if (!formSubmission) {
      actions.getSubmissionInfo({ group });
    }
  }, []);

  return (
    <InputDialog
      className={classes.root}
      data-hook={SubmissionViewModalDataHooks.Main}
      title={t('app.my-bookings-widget.submission-modal.title')}
      isOpen
      onClose={() => actions.closeModal({})}
      primaryButton={{
        text: t('app.my-bookings-widget.submission-modal.close-button.label'),
        onClick: () => actions.closeModal({}),
      }}
    >
      {!formSubmission ? (
        <div
          data-hook={SubmissionViewModalDataHooks.Spinner}
          className={classes.spinnerContainer}
        >
          <CenteredSpinner />
        </div>
      ) : (
        <div
          className={classes.content}
          data-hook={SubmissionViewModalDataHooks.Content}
        >
          {formSubmission!.submissions?.map((submission) => (
            <div
              data-hook={SubmissionViewModalDataHooks.Submission}
              key={submission.key}
            >
              <Submission submission={submission} />
            </div>
          ))}
        </div>
      )}
    </InputDialog>
  );
};

export default SubmissionViewModal;
